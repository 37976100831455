import styled from 'styled-components';
import axios from 'axios';
import { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import { Input, Button } from '../../components/lib';
import { Context } from '../../context/auth';
import { prod } from '../../config/config';

const StyledLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  height: auto;
  background-color: white;
`;

const StyledLoginForm = styled.div`
  margin: 0 auto;
  max-width: 450px;
  width: 100%;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;

  @media screen and (max-width: 420px) {
    padding: 0 4rem;
  }

  .logo-container {
    max-width: 150px;
    margin-top: 40px;
    margin-bottom: 50px;
  }

  .logo-container img {
    max-width: 100%;
    height: auto;
  }
  form {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    font-family: 'Spartan', sans-serif;
    font-size: 1.65rem;
  }

  h4 {
    font-size: 2rem;
    font-variation-settings: 'wght' 550;
    font-family: 'Spartan', sans-serif;
    margin-bottom: 1rem;
  }
`;

export const ChangePasswordPage = () => {
  const { token, id, getAuthenticatedUser } = useContext(Context);

  const navigate = useNavigate();

  useEffect(() => {
    if (getAuthenticatedUser === true) {
      navigate('/');
    }
  }, []);

  const [passwordFields, setPasswordFields] = useState({
    password: '',
    confirmPassword: '',
  });

  const submitPassword = (e) => {
    e.preventDefault();

    if (passwordFields.password !== passwordFields.confirmPassword) {
      return toast.error('Passwords do not match');
    }

    if (passwordFields.password.length < 8) {
      return toast.error('Password must be at least 8 characters');
    }

    axios
      .post(
        `${prod}/api/user/change_password/${id}`,
        {
          password: passwordFields.password,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then(() => {
        toast.success('Password changed successfully');
        Cookies.set('getAuthenticatedUserInfo', true);

        window.location.reload();
      })
      .catch(() => {
        toast.error('An error occured, please try again. If this continues, please your Cardiac Coach.');
      });
  };

  return (
    <StyledLoginContainer>
      <StyledLoginForm>
        <h4 style={{ textAlign: 'center' }}>Create a Password</h4>
        <p style={{ textAlign: 'center' }}>
          Please enter a secure password with a minimum of eight characters. You will need this password when accessing
          your <span style={{ fontWeight: 'bold' }}>CP+R</span> account in the future to view documents, videos and
          other important information
        </p>
        <form onSubmit={submitPassword}>
          <Input
            type='password'
            name='newPassword'
            placeholder='New Password'
            value={passwordFields.password}
            onChange={(e) => setPasswordFields({ ...passwordFields, password: e.target.value })}
            required
          />
          <Input
            type='password'
            name='confirmPassword'
            placeholder='Confirm Password'
            value={passwordFields.confirmPassword}
            onChange={(e) => setPasswordFields({ ...passwordFields, confirmPassword: e.target.value })}
            required
          />
          <Button type='submit'>Reset Password</Button>
        </form>
      </StyledLoginForm>
    </StyledLoginContainer>
  );
};
