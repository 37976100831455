import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy';
import styled from 'styled-components';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Context } from '../../context/auth';
import { prod } from '../../config/config';

const StyledPageContainer = styled.main`
  max-width: 1100px;
  padding: 2.5rem 0rem;
  margin: 0 auto;
`;

export const VideoPlayerPage = () => {
  const [video, setVideo] = useState({});

  const { video_id } = useParams();
  const { id, token } = useContext(Context);

  useEffect(() => {
    axios
      .get(`${prod}/api/user/video/${id}/${video_id}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setVideo(res.data);
      })
      .catch(() => {
        toast.error('An error occured when attempting to fetch this video. Please contact your cardiac coach.');
      });
  }, []);

  return (
    <>
      {video && (
        <StyledPageContainer>
          <ReactPlayer url={video.url} controls style={{ padding: '0 1rem', margin: '0 auto' }} width='100%' />
        </StyledPageContainer>
      )}
    </>
  );
};
