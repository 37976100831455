import styled from 'styled-components';
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Image } from 'cloudinary-react';
import { Context } from '../../context/auth';
import { prod } from '../../config/config';

const StyledPageContainer = styled.main`
  max-width: 900px;
  padding: 2.5rem 2.5rem;
  margin: 0 auto;
`;

const StyledMessageBox = styled.div`
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  padding: 2.5rem 2.5rem;
  margin: 0 auto;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.white};

  .message-cc {
    width: 150px;
    img {
      max-width: 100%;
      height: auto;
    }
  }

  h2 {
    font-variation-settings: 'wght' 600;
    font-size: 2rem;
    margin: 2rem 0 3rem 0;
  }
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  input {
    padding: 8px;
    font-size: 15px;
    line-height: 30px;
    color: ${(props) => props.theme.black};
    border: 1px solid ${(props) => props.theme.lightgray};
    border-radius: 6px;
    background: none;
    transition: all 300ms ease 0s;
    margin: 3rem 0;
    font-variation-settings: 'wght' 650;
  }

  textarea {
    height: 200px;
    padding: 8px;
    font-size: 15px;
    color: ${(props) => props.theme.black};
    border: 1px solid ${(props) => props.theme.lightgray};
    border-radius: 6px;
    background: none;
    transition: all 300ms ease 0s;
    font-family: ${(props) => props.theme.main};
  }

  button {
    margin-top: 1rem;
    margin-left: auto;
    width: max-content;
    font-size: 1.4rem;
    background-color: ${(props) => props.theme.black};
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
    color: ${(props) => props.theme.yellow};
    font-variation-settings: 'wght' 650;
    text-transform: uppercase;
    transition: 0.2s ease-in-out;
    border: 1px solid ${(props) => props.theme.white};
    padding: 1rem 1.5rem;

    :hover {
      color: ${(props) => props.theme.lightgray};
      transition: 0.2s ease-in-out;
    }
  }
`;

export const MessageCoachPage = () => {
  const [staffMember, setStaffMember] = useState(null);

  const { id, token } = useContext(Context);

  const { staff_id } = useParams();

  useEffect(() => {
    axios
      .get(`${prod}/api/user/get_staff_details/${id}/${staff_id}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setStaffMember(res.data);
      })
      .catch(() => {
        toast.error(
          'An error occured when attempting to load the data required to message this member of your team. Please contact your Cardiac Coach.'
        );
      });
  }, [staff_id]);

  if (!staffMember) {
    return null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const { subject, message } = e.target;

    axios
      .post(`${prod}/api/user/send_email`, {
        id,
        subject: subject.value,
        message: message.value,
        staff_id: staffMember._id,
      })
      .then(() => {
        toast.success('Message sent successfully.');
        subject.value = '';
        message.value = '';
      })
      .catch(() => {
        toast.error('An error occured when attempting to send your message. Please try again.');
      });
  };

  return (
    <StyledPageContainer>
      <StyledMessageBox>
        <div className='message-cc'>
          <Image
            cloudName='dmyzytt5mrdsygn90e3ismi'
            publicId={staffMember.field_data.image.user_image}
            format={staffMember.field_data.image.format}
            className='rounded-circle img-thumbnail'
          />
        </div>
        <h2>Message {staffMember.field_data.user_name}</h2>
        <form onSubmit={handleSubmit}>
          <input id='subject' type='text' placeholder='Subject' required />
          <textarea id='message' placeholder='Message' required />
          <button type='submit'>Send</button>
        </form>
      </StyledMessageBox>
    </StyledPageContainer>
  );
};
