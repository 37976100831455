import axios from 'axios';
import { prod } from '../config/config';

export const lastActive = (id, token) => {
  const checkDate = window.localStorage.getItem('loggedIn');

  if (!checkDate || new Date(checkDate).toDateString() !== new Date().toDateString()) {
    axios
      .patch(
        `${prod}/api/user/last_logged_in/${id}`,
        {},
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then(() => {
        window.localStorage.setItem('loggedIn', new Date().toISOString());
      })
      .catch(() => {});
  }
};
