import styled from 'styled-components';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Input, Button, Footer } from '../../components/lib';
import MainLogo from '../../assets/main-logo.png';
import { prod } from '../../config/config';

const StyledLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  height: auto;
  background-color: white;
`;

const StyledLoginForm = styled.div`
  margin: 0 auto;
  max-width: 350px;
  width: 100%;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;

  @media screen and (max-width: 420px) {
    padding: 0 4rem;
  }

  .logo-container {
    max-width: 150px;
    margin-top: 40px;
    margin-bottom: 50px;
  }

  .logo-container img {
    max-width: 100%;
    height: auto;
  }
  form {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ResetPasswordPage = () => {
  const [passwordFields, setPasswordFields] = useState({
    password: '',
    confirmPassword: '',
  });
  const [validToken, setValidToken] = useState(false);

  const { token } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${prod}/api/auth/reset_password/${token}`)
      .then(() => {
        setValidToken(true);
      })
      .catch(() => {
        setValidToken(false);
        navigate('/');
      });
  }, []);

  const submitPassword = (e) => {
    e.preventDefault();

    if (passwordFields.password !== passwordFields.confirmPassword) {
      return toast.error('Passwords do not match');
    }

    axios
      .post(`${prod}/api/auth/reset_password/${token}`, {
        password: passwordFields.password,
      })
      .then(() => {
        toast.success('Password reset successfully');
        setTimeout(() => {
          navigate('/');
        }, 500);
      })
      .catch(() => {
        toast.error('Password reset failed');
      });
  };

  if (!validToken) {
    return '';
  }

  return (
    <StyledLoginContainer>
      <StyledLoginForm>
        <div className='logo-container'>
          <img src={MainLogo} alt='logo' />
        </div>
        <form onSubmit={submitPassword}>
          <Input
            type='password'
            name='newPassword'
            placeholder='New Password'
            value={passwordFields.password}
            onChange={(e) => setPasswordFields({ ...passwordFields, password: e.target.value })}
          />
          <Input
            type='password'
            name='confirmPassword'
            placeholder='Confirm Password'
            value={passwordFields.confirmPassword}
            onChange={(e) => setPasswordFields({ ...passwordFields, confirmPassword: e.target.value })}
          />
          <Button type='submit'>Reset Password</Button>
        </form>
      </StyledLoginForm>
      <Footer />
    </StyledLoginContainer>
  );
};
