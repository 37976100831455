import { Routes, Route } from 'react-router-dom';

import { Login, ResetPasswordPage, AppDownload } from './pages';

const UnauthenticatedApp = () => (
  <Routes>
    <Route exact path='/' element={<Login />} />
    <Route path='/reset-password/:token' element={<ResetPasswordPage />} />
    <Route path='/mobile-app-store/' element={<AppDownload />} />
    <Route path='*' element={<Login />} />
  </Routes>
);

export default UnauthenticatedApp;
