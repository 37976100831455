import styled from 'styled-components';
import { useState, useEffect, useContext } from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';
import ReactPlayer from 'react-player/lazy';
import { Context } from '../../context/auth';
import { PageSpinner, FooterQuote } from '../../components/lib';
import { assessment_report } from '../../assets/profilebuttons';
import { prod } from '../../config/config';

const UnderstandAssessmentCard = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 2rem;
  display: flex;
  padding: 3rem;
  background-color: ${(props) => props.theme.lightblue};

  .content {
    font-family: 'Spartan', sans-serif;
    flex-basis: 50%;

    h2 {
      font-size: 2rem;
      text-transform: uppercase;
      font-variation-settings: 'wght' 700;
      max-width: 350px;
      margin: 0 auto;
      margin-bottom: 2rem;
    }

    p {
      padding: 1rem;
      padding-left: 0;
      font-size: 1.4rem;
      font-variation-settings: 'wght' 550;
      line-height: 2.25rem;
    }
  }

  img {
    width: auto;
    max-height: 320px;
    aspect-ratio: 16/9;
  }

  @media screen and (max-width: ${(props) => props.theme.mobile}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;

    img {
      display: none;
    }
  }

  margin-bottom: 2rem;
`;

const YourAssessmentsContainer = styled.div`
  h2 {
    font-size: 6rem;
    font-family: 'Triplex', sans-serif;
    text-transform: uppercase;
    color: #0098b5;
    text-align: center;
    margin-bottom: 1rem;
  }

  .assessments {
    display: grid;
    gap: 1rem;

    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: ${(props) => props.theme.mobile}) {
      grid-template-columns: repeat(1, 1fr);
      padding: 1rem;
    }
  }

  margin-bottom: 2rem;
`;

const StyledLinkBox = styled.div`
  width: 50%;

  @media screen and (max-width: ${(props) => props.theme.mobile}) {
    width: 100%;
  }

  .box {
    // make top left and bottom right have a border radius
    font-size: 1.45rem;
    font-variation-settings: 'wght' 650;

    padding: 1rem;

    color: ${(props) => props.theme.black};

    background-color: ${(props) => props.theme.yellow};

    :hover {
      color: ${(props) => props.theme.blue};
      transition: 0.2s ease-in-out;
    }

    text-align: center;
  }
`;

const LinkBox = ({ title, url }) => (
  <StyledLinkBox>
    <a href={url} target='_blank' rel='noreferrer' style={{ width: '50%' }}>
      <div className='box'>
        <span>{title}</span>
      </div>
    </a>
  </StyledLinkBox>
);

const YourAssessmentsCard = styled.div`
  display: flex;
  background-color: #4db848;
  padding: 0.25rem;
  flex-basis: 33.33%;
  border-top-left-radius: 2.5rem;
  border-bottom-right-radius: 2.5rem;

  img {
    width: auto;
    height: 100px;
  }

  .details {
    font-family: 'Spartan', sans-serif;
    color: white;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    .date {
      font-size: 1.4rem;
      color: ${(props) => props.theme.white};
      font-variation-settings: 'wght' 450;
    }

    .title {
      font-size: 1.75rem;
      color: ${(props) => props.theme.white};
      font-variation-settings: 'wght' 600;
      text-transform: uppercase;
    }
  }

  :hover {
    cursor: pointer;
    transition: 0.2s ease-in-out;
    background-color: #3f8b3b;
  }
`;

const YourStatsContainer = styled.div`
  h2 {
    font-size: 6rem;
    font-family: 'Triplex', sans-serif;
    text-transform: uppercase;
    color: #85378b;
    text-align: center;
    margin-bottom: 2rem;
  }

  .stats-table {
    width: 100%;
    overflow-x: scroll;
    border: 3px solid #85378b;
  }

  th {
    font-variation-settings: 'wght' 600;
  }

  table {
    border-collapse: collapse;
  }
  table td,
  table th {
    border: 1px solid #85378b52;
  }
  table tr:first-child th {
    border-top: 0;
  }
  table tr:last-child td {
    border-bottom: 0;
  }
  table tr td:first-child,
  table tr th:first-child {
    border-left: 0;
  }
  table tr td:last-child,
  table tr th:last-child {
    border-right: 0;
  }

  th,
  td {
    font-size: 1.4rem;
    padding: 1.5rem;
    font-family: 'Spartan', sans-serif;
  }

  h4 {
    font-size: 1.4rem;
    font-variation-settings: 'wght' 450;
    color: #4b4b4b;
    margin-bottom: 0.5rem;
  }

  .value {
    font-size: 1.4rem;
    font-variation-settings: 'wght' 600;
  }
`;

export const AssessmentsPage = () => {
  const { id, token } = useContext(Context);
  const [assessments, setAssessments] = useState({
    assessment_reports: [],
    phlebotomy_reports: [],
  });
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    axios
      .get(`${prod}/api/assessment/get_assessment_data/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data !== 'No data') {
          setAssessments([...res.data]);
        }
      })
      .catch(() => {
        toast.error('An error occured when attempting to fetch your assessments. Please contact your cardiac coach.');
      })
      .finally(() => {
        setLoading(false);
      });

    axios
      .get(`${prod}/api/user/assessment_reports/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setReports({
          ...res.data,
        });
      });
  }, [id]);

  if (loading) {
    return (
      <>
        <PageSpinner />
        <FooterQuote message="If you're not assessing, you're guessing" />
      </>
    );
  }

  return (
    <>
      <div
        style={{
          minHeight: 'calc(100vh - 209px)',
          maxWidth: '1100px',
          margin: '0 auto',
        }}
      >
        <UnderstandAssessmentCard>
          <ReactPlayer
            url='https://vimeo.com/731132852/fafa3fd22f'
            controls
            style={{ padding: '0 1rem', margin: '0 auto' }}
            width='100%'
          />
          <div className='content'>
            <h2>Understanding Your Assessments</h2>
            <p>
              On this page, you can find your individual assessment reports and a table of all your results so you can
              see your progress over time. There is also a video guide to help you fully understand your assessment
              report. Don&apos;t forget you can also speak to your Cardiac Coach or Experience Manager at any time if
              you have questions about your results. You can contact them directly from your Clubhouse homepage
            </p>
          </div>
        </UnderstandAssessmentCard>

        <div
          className='hads-exe'
          style={{
            fontFamily: 'Spartan',
            display: 'flex',
            gap: '6rem',
            marginBottom: '2rem',
          }}
        >
          <LinkBox url={`https://cpandrforms.netlify.app/had_form/${id}`} title='HAD Scale' />
          <LinkBox url={`https://cpandrforms.netlify.app/exe_form/${id}`} title='Exercise + Confidence' />
        </div>

        <YourAssessmentsContainer>
          <h2>Your Assessments</h2>
          <div className='assessments'>
            {reports && reports.assessment_reports && reports.assessment_reports.length > 0 ? (
              reports.assessment_reports.map((assessment, i) => (
                <a key={assessment._id} href={assessment.url}>
                  <YourAssessmentsCard>
                    <img src={assessment_report} alt='assessment report' />
                    <div className='details'>
                      <div className='date'>{assessment.upload_date.split('T')[0]}</div>
                      <div className='title'>
                        Report{' '}
                        {(i + 1).length > 1
                          ? reports.assessment_reports.length - i
                          : `0${reports.assessment_reports.length - i}`}
                      </div>
                    </div>
                  </YourAssessmentsCard>
                </a>
              ))
            ) : (
              <div>No Assessments to Display</div>
            )}
          </div>
        </YourAssessmentsContainer>

        <YourAssessmentsContainer>
          <h2>Phlebotomy Reports</h2>
          <div className='assessments'>
            {reports && reports.phlebotomy_reports && reports.phlebotomy_reports.length > 0 ? (
              reports.phlebotomy_reports.map((phlebotomy, i) => (
                <a key={phlebotomy._id} href={phlebotomy.url}>
                  <YourAssessmentsCard>
                    <img src={assessment_report} alt='phlebotomy report' />
                    <div className='details'>
                      <div className='date'>{phlebotomy.upload_date.split('T')[0]}</div>
                      <div className='title'>
                        Report{' '}
                        {(i + 1).length > 1
                          ? reports.phlebotomy_reports.length - i
                          : `0${reports.phlebotomy_reports.length - i}`}
                      </div>
                    </div>
                  </YourAssessmentsCard>
                </a>
              ))
            ) : (
              <div>No Phlebotomy Reports to Display</div>
            )}
          </div>
        </YourAssessmentsContainer>

        {assessments && assessments.length > 0 && (
          <YourStatsContainer>
            <h2>Your Stats</h2>
            <div className='stats-table'>
              <table>
                {assessments.map((row, i) => (
                  <tr key={row}>
                    {row.map((col, j) => {
                      if (i === 0) {
                        if (j > 0) {
                          return <th>{col}</th>;
                        }
                      }
                      if (
                        (row[0] === 'CPET' || row[0] === 'Biodex') &&
                        col !== '-' &&
                        col !== 'CPET' &&
                        col !== 'Biodex'
                      ) {
                        return (
                          <td>
                            <a href={col}>Link</a>
                          </td>
                        );
                      }

                      if (j === 0) {
                        return null;
                      }
                      return (
                        <td>
                          <h4>{row[0]}</h4>
                          <div className='value'>{col}</div>
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </table>
            </div>
          </YourStatsContainer>
        )}
      </div>
      <FooterQuote message="If you're not assessing, you're guessing" />
    </>
  );
};
