import styled, { css, keyframes } from 'styled-components';
import { ImSpinner2 } from 'react-icons/im';
import { BsInstagram, BsTwitter, BsLinkedin } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import logo from '../../assets/clubhouse-logo.svg';
import { instagram_url, twitter_url, linkedin_url } from '../../constants/urls';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  500% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const animation = css`
  ${spin} 2s infinite linear;
`;
export const Spinner = styled(ImSpinner2)`
  animation: ${animation};
`;
Spinner.defaultProps = {
  'aria-label': 'loading',
};

export function FullPageSpinner() {
  return (
    <div
      style={{
        fontSize: '3rem',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner />
    </div>
  );
}

export function PageSpinner() {
  return (
    <div
      style={{
        fontSize: '3rem',
        display: 'flex',
        margin: '1rem',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner />
    </div>
  );
}

export const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  font-size: 15px;
  line-height: 30px;
  color: ${(props) => props.theme.black};
  border: 1px solid ${(props) => props.theme.lightgray};
  border-radius: 6px;
  background: none;
  transition: all 300ms ease 0s;
  margin-bottom: 2rem;
`;

export const Button = styled.button`
  width: 70%;
  padding: 8px 12px;
  font-size: 15px;
  line-height: 24px;
  border-radius: 6px;
  background: none;
  transition: all 300ms ease 0s;
  margin-top: 2.5rem;
  background-color: ${(props) => props.theme.black};
  color: ${(props) => props.theme.white};
  border: none;
`;

const StyledLogoContainer = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  width: 130px;
  font-variation-settings: 'wght' 650;
  transition: color 0.2s ease-in-out;

  .image {
    width: 75px;
    img {
      width: 100%;
      max-height: auto;
    }
  }

  :hover {
    color: ${(props) => props.theme.mediumgray};
    transition: color 0.2s ease-in-out;
  }
`;

export const HeaderLogo = () => (
  <StyledLogoContainer to='/'>
    <div className='image'>
      <img src={logo} alt='Main CP+R logo' />
    </div>
  </StyledLogoContainer>
);

const StyledFooter = styled.footer`
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  padding-bottom: 1.8rem;
  width: 100%;
  font-size: 1.2rem;
  color: ${(props) => props.theme.mediumgray};

  div {
    margin-bottom: 1.5rem;
  }

  .icons a {
    padding: 1rem;
    font-size: 1.9rem;
    transition: color 0.3s ease-in-out;
  }
  a {
    transition: color 0.3s ease-in-out;
  }
  a:hover {
    color: ${(props) => props.theme.black};
    transition: color 0.3s ease-in-out;
  }
`;

export const Footer = () => (
  <StyledFooter>
    <div className='icons'>
      <a href={instagram_url} target='_blank' rel='noreferrer noopener'>
        <BsInstagram />
      </a>
      <a href={twitter_url} target='_blank' rel='noreferrer noopener'>
        <BsTwitter />
      </a>
      <a href={linkedin_url} target='_blank' rel='noreferrer noopener'>
        <BsLinkedin />
      </a>
    </div>
    <a href='https://cpandr.co.uk' target='_blank' rel='noreferrer noopener'>
      © 2014 - {new Date().getFullYear()} CP+R
    </a>
  </StyledFooter>
);

const StyledQuote = styled.footer`
  padding: 3rem;
  font-size: 4rem;
  font-variation-settings: 'wght' 700;
  display: grid;
  align-self: end; /* The trick */
  width: 100%;
  color: white;
  margin-top: 2rem;
  background-color: ${(props) => props.theme.black};
  font-family: 'Spartan';
  text-align: center;
  text-transform: uppercase;
`;

export const FooterQuote = ({ message }) => (
  <StyledQuote>
    <p>{message}</p>
  </StyledQuote>
);
