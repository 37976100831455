import AllStar from './AllStar.png';
import Champion from './Champion.png';
import Pro from './Pro.png';
import Qualifier from './Qualifier.png';
import Tracker from './Tracker.png';
import Winner from './Winner.png';
import CCA from './CCA.png';

// const levels = [AllStar, Champion, Pro, Qualifier, Tracker, Winner];

const levels = [
  {
    name: 'Athlete Academy',
    image: CCA,
  },
  {
    name: 'Tracker',
    image: Tracker,
  },
  {
    name: 'Upkeep',
    image: Qualifier,
  },
  {
    name: 'Winner',
    image: Winner,
  },
  {
    name: 'Champion',
    image: Champion,
  },
  {
    name: 'Pro',
    image: Pro,
  },
  {
    name: 'All Star',
    image: AllStar,
  },
];

export default levels;
