import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { BsLockFill } from 'react-icons/bs';
import { PageSpinner, FooterQuote } from '../../components/lib';
import { Context } from '../../context/auth';
import { prod } from '../../config/config';

const StyledEducationContainer = styled.main`
  max-width: 1100px;
  padding: 2.5rem 2.5rem;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-top: 0;

  h2 {
    font-size: 2.5rem;
  }
`;

const StyledRecommendedText = styled.p`
  font-family: 'Spartan';

  font-size: 2.5rem;
  font-variation-settings: 'wght' 600;
  margin: 2rem 0;
`;

const StyledEducationPost = styled.div`
  flex-basis: 50%;

  margin: 0 auto;
  padding: 1.5rem;

  .container {
    background-color: ${(props) => props.theme.lightblue};
    transition: background-color 0.2s ease-in-out;
    padding: 2rem;

    img {
      width: 100%;
      height: 320px;
      object-fit: cover;
    }

    h2 {
      margin-top: 1.5rem;
      font-size: 2rem;
      font-variation-settings: 'wght' 650;
      text-align: center;
      text-transform: uppercase;
    }
  }
  @media screen and (max-width: ${(props) => props.theme.mobile}) {
    flex-basis: 100%;
  }

  .container:not(.locked):hover {
    background-color: ${(props) => props.theme.yellow};
  }

  .container.locked {
    background-color: ${(props) => props.theme.lightgray};
  }

  .locked {
    position: relative;

    .lock {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2rem;
      color: ${(props) => props.theme.lightgray};
      z-index: 1;
    }

    .disabled {
      width: 100%;
      height: 100%;
      filter: brightness(75%) saturate(140%) grayscale(100%);
    }
  }
`;

export const EducationCard = ({ post: { card_image, title, post_id, toggled } }) => (
  <StyledEducationPost>
    {toggled ? (
      <a href={`/education/${post_id}`}>
        <div className='container'>
          <img src={card_image} alt='Cover' loading='lazy' />
          <h2>{title}</h2>
        </div>
      </a>
    ) : (
      <div className='container locked'>
        <div className='lock'>
          <BsLockFill size='5em' />
        </div>
        <div className='disabled'>
          <img src={card_image} alt='Cover' loading='lazy' />
        </div>
        <h2>{title}</h2>
      </div>
    )}
  </StyledEducationPost>
);

export const EducationPage = () => {
  const [postsByCategory, setPostsByCategory] = useState({
    all: [],
    unsorted: [],
    exercise: [],
    cardiac: [],
    nutrition: [],
    lifestyle: [],
  });

  const [activeCategory, setActiveCategory] = useState('all');

  const { id, token } = useContext(Context);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${prod}/api/user/education_posts/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        const categorizedPosts = res.data.reduce(
          (acc, post) => {
            if (!post.category || !acc[post.category]) {
              acc.unsorted.push(post);
            } else {
              acc[post.category].push(post);
            }
            acc.all.push(post);
            return acc;
          },
          { all: [], setting_up: [], cardiac: [], unsorted: [], exercise: [], nutrition: [], lifestyle: [] }
        );

        Object.keys(categorizedPosts).forEach((category) => {
          categorizedPosts[category] = categorizedPosts[category].sort((a, b) => a.order_index - b.order_index);

          categorizedPosts[category] = categorizedPosts[category].sort((a, b) => b.toggled - a.toggled);

          categorizedPosts[category] = categorizedPosts[category].sort((a, b) => {
            if (a.priortise && !b.priortise) {
              return -1;
            }
            if (!a.priortise && b.priortise) {
              return 1;
            }
            return 0;
          });
        });

        setPostsByCategory(categorizedPosts);
      })
      .catch(() => {
        toast.error('An error occured retrieving your next assessment date');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <>
        <PageSpinner />
        <FooterQuote message='Knowledge is power' />
      </>
    );
  }

  const StyledCategories = styled.div`
    display: flex;
    max-width: 1100px;
    margin: 2rem auto;
    button {
      all: unset;
    }

    padding: 1.5rem;

    overflow-x: scroll;

    h3 {
      font-size: 2rem;
      font-variation-settings: 'wght' 500;
      font-family: 'Spartan';
      cursor: pointer;
      text-transform: capitalize;
    }

    h3:hover {
      font-variation-settings: 'wght' 600;
      transition: font-variation-settings 0.2s ease-in-out;
    }

    .active {
      font-variation-settings: 'wght' 650;
    }

    .category-buttons {
      background-color: ${(props) => props.theme.blue};
      padding: 1.5rem;
      display: flex;
      justify-content: space-around;
      width: 100%;
      white-space: nowrap;
      @media screen and (max-width: ${(props) => props.theme.mobile}) {
        gap: 2rem;
        justify-content: unset;
      }
    }
    margin-bottom: 0;
  `;

  const Categories = () => (
    <StyledCategories>
      <div className='category-buttons'>
        {Object.keys(postsByCategory).map((category) => {
          if (category === 'unsorted') {
            return null;
          }

          if (category === 'cardiac') {
            return (
              <button key={category} onClick={() => setActiveCategory(category)} type='button'>
                <h3 className={activeCategory === category ? 'active' : ''}>Clinical</h3>
              </button>
            );
          }

          if (category === 'setting_up') {
            return (
              <button key={category} onClick={() => setActiveCategory(category)} type='button'>
                <h3 className={activeCategory === category ? 'active' : ''}>Setting Up</h3>
              </button>
            );
          }

          return (
            <button key={category} onClick={() => setActiveCategory(category)} type='button'>
              <h3 className={activeCategory === category ? 'active' : ''}>{category}</h3>
            </button>
          );
        })}
      </div>
    </StyledCategories>
  );

  return (
    <>
      <div
        style={{
          minHeight: 'calc(100vh - 209px)',
        }}
      >
        <Categories />
        {postsByCategory[activeCategory].some((post) => post.priortise) ? (
          <StyledRecommendedText style={{ textAlign: 'center', margin: '2rem 0' }}>
            Recommended by your coach
          </StyledRecommendedText>
        ) : null}
        <StyledEducationContainer>
          {postsByCategory[activeCategory].map((post) => (
            <EducationCard key={post.post_id} post={post} />
          ))}
        </StyledEducationContainer>
        );
      </div>
      <FooterQuote message='Knowledge is power' />
    </>
  );
};
