import { useState, useContext, useRef, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FiLogOut } from 'react-icons/fi';
import { AiOutlineMenu } from 'react-icons/ai';
import { Context } from '../../context/auth';
import { HeaderLogo } from '../lib';
import routes from '../../routes';

const StyledNavbar = styled.nav`
  padding: 1rem 3rem;
  background-color: #ffe400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 2;
  top: 0;
  font-family: 'Spartan';

  button {
    font-size: 1.4rem;
    background-color: inherit;
    font-variation-settings: 'wght' 650;
    color: black;
    border: none;
    transition: color 0.2s ease-in-out;
    :hover {
      color: black;
      transition: color 0.2s ease-in-out;
    }
  }
`;
const StyledDropdown = styled.ul`
  width: 130px;
  position: absolute;
  top: 5rem;
  right: 2rem;
  z-index: 1;
  background-color: ${(props) => props.theme.white};
  box-shadow: rgb(24 26 29 / 10%) 0px 3px 5px 0px;
  border: 1px solid ${(props) => props.theme.lightgray};
  display: ${(props) => (props.menuOpen ? 'block' : 'none')};

  li button {
    border: none;
    background-color: inherit;
    font-size: 1.4rem;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    border-bottom: 1px solid ${(props) => props.theme.lightgray};
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    :hover {
      background-color: ${(props) => props.theme.lightgray};
      transition: background-color 0.2s ease-in-out;
    }
  }
`;
const StyledNavLinks = styled.ul`
  display: flex;
  font-size: 1.3rem;
  align-items: center;
  justify-content: space-between;
  width: 55rem;
  padding: 0 5rem;
  li {
    margin: 0px 5px;
    text-transform: uppercase;
    font-variation-settings: 'wght' 550;
  }

  a {
    transition: color 0.2s ease-in-out;

    :hover {
      color: ${(props) => props.theme.mediumgray};
      transition: color 0.2s ease-in-out;
    }

    &.active {
      // color: ${(props) => props.theme.pink};
      font-variation-settings: 'wght' 650;
    }
  }
  @media screen and (max-width: ${(props) => props.theme.mobile}) {
    display: none;
  }
`;
const StyledProfileButton = styled.div`
  font-style: normal;
  font-weight: 300;

  @media screen and (max-width: ${(props) => props.theme.mobile}) {
    display: none;
  }
`;
const StyledMenuButton = styled.div`
  display: none;
  @media screen and (max-width: ${(props) => props.theme.mobile}) {
    z-index: 1;
    display: block;
  }
`;

const StyledMobileMenu = styled.ul`
  width: 100vw;
  height: ${(props) => (props.menuOpen ? 100 : 0)}vh;
  overflow: scroll;
  background-color: ${(props) => props.theme.offwhite};
  // border-top: 1px solid ${(props) => props.theme.lightgray};
  position: absolute;
  top: 6.75rem;
  left: 0;
  transition: height 0.2s ease-in-out;

  li button,
  a {
    font-family: inherit;
    border: none;
    background-color: inherit;
    font-size: 1.6rem;
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    :hover {
      background-color: ${(props) => props.theme.lightgray};
      transition: background-color 0.2s ease-in-out;
    }

    &.active {
      color: ${(props) => props.theme.pink};
    }
  }

  hr {
    width: 90%;
    opacity: 0.4;
    margin: 1rem auto;
  }
`;

const DesktopNavLinks = () => (
  <StyledNavLinks>
    {routes.map((route) => (
      <li key={route.title}>
        <NavLink to={route.path}>{route.title}</NavLink>
      </li>
    ))}
  </StyledNavLinks>
);

const DesktopProfileButton = () => {
  const { user, logout } = useContext(Context);
  const ref = useRef();
  const [menuOpen, setMenuOpen] = useState(false);

  const actionLogout = () => {
    setMenuOpen(false);
    logout();
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (e.target.id === 'menu-button') {
        return;
      }
      if (menuOpen && ref.current && !ref.current.contains(e.target)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [menuOpen]);
  return (
    <StyledProfileButton>
      <button type='button' id='menu-button' onClick={() => setMenuOpen((prev) => !prev)}>
        Hello {user.split(' ')[0]}
      </button>
      <StyledDropdown menuOpen={menuOpen} ref={ref}>
        {/* <li>
          <button type='button' onClick={() => actionNavigate('settings')}>
            <span>Settings</span> <FiSettings />
          </button>
        </li> */}
        <li>
          <button type='button' onClick={actionLogout}>
            <span>Logout</span> <FiLogOut />
          </button>
        </li>
      </StyledDropdown>
    </StyledProfileButton>
  );
};

const MobileMenuButton = () => {
  const { logout } = useContext(Context);
  const history = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const actionLogout = () => {
    setMenuOpen(false);
    logout();
  };

  useEffect(() => {
    setMenuOpen(false);
  }, [history]);
  return (
    <StyledMenuButton>
      <button type='button' onClick={() => setMenuOpen((prev) => !prev)}>
        <AiOutlineMenu />
      </button>
      <StyledMobileMenu menuOpen={menuOpen}>
        {routes.map((route) => (
          <li key={route.title}>
            {/* <button type='button'>
              <span>{route.title}</span>
            </button> */}
            <NavLink to={route.path}>{route.title}</NavLink>
          </li>
        ))}
        <hr />
        {/* <li>
          <button type='button' onClick={() => actionNavigate('settings')}>
            <span>Settings</span> <FiSettings />
          </button>
        </li> */}
        <li>
          <button type='button' onClick={actionLogout}>
            <span>Logout</span> <FiLogOut />
          </button>
        </li>
      </StyledMobileMenu>
      {/* <StyledDropdown menuOpen={menuOpen}>
        <li>
          <button type='button' onClick={() => actionNavigate('/settings')}>
            <span>Settings</span> <FiSettings />
          </button>
        </li>
        <li>
          <button type='button' onClick={actionLogout}>
            <span>Logout</span> <FiLogOut />
          </button>
        </li>
      </StyledDropdown> */}
    </StyledMenuButton>
  );
};

const DesktopNav = () => (
  <>
    <DesktopNavLinks />
    <DesktopProfileButton />
  </>
);

const Navbar = () => {
  const location = useLocation();

  if (location.search === '?embed=inline') {
    return null;
  }
  return (
    <StyledNavbar>
      <HeaderLogo />
      <DesktopNav />
      <MobileMenuButton />
    </StyledNavbar>
  );
};

export default Navbar;
