import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import 'github-markdown-css/github-markdown-light.css';
import ReactPlayer from 'react-player';
import { Context } from '../../context/auth';
import { prod } from '../../config/config';

const StyledEducationPost = styled.div`
  margin: 2rem auto;
  max-width: 1100px;
  padding: 1rem;

  // img {
  //   max-width: 150px;
  //   max-height: 150px;
  // }

  .markdown-body {
    font-family: 'Spartan', sans-serif;

    h1,
    h2,
    h3,
    h4 {
      margin-top: 2rem;
      margin-bottom: 2rem;
      border-bottom: none;
      font-variation-settings: 'wght' 550;
    }

    strong {
      font-weight: unset;
      font-variation-settings: 'wght' 650;
    }

    ol,
    ul {
      list-style: disc;
    }
  }
`;

// card_image, content, media, title

const EducationPost = ({ post: { content, media } }) => (
  <StyledEducationPost>
    {media && <ReactPlayer url={media} controls style={{ marginBottom: '2rem' }} width='100%' />}
    <ReactMarkdown className='markdown-body' skipHtml>
      {content}
    </ReactMarkdown>
  </StyledEducationPost>
);

export const EducationPostPage = () => {
  const { id, token } = useContext(Context);

  const [post, setPost] = useState(null);
  const { education_post } = useParams();

  useEffect(() => {
    axios
      .get(`${prod}/api/user/education_post/${id}/${education_post}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data) {
          setPost(res.data);
        }
      })
      .catch(() => {
        toast.error('An error occured when attempting to fetch this post. Please contact your cardiac coach.');
      });
  }, []);

  if (!post) {
    return (
      <div>
        <h2>No Post to Display</h2>
      </div>
    );
  }

  return (
    <>
      <EducationPost post={post} />
    </>
  );
};
