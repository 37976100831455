import { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import ReactHtmlParser from 'html-react-parser';
import SignaturePad from 'react-signature-pad-wrapper';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Context } from '../../context/auth';
import { PageSpinner } from '../../components/lib';
import { prod } from '../../config/config';

const StyledForm = styled.div`
  .main-container {
    max-width: 1110px;
    margin: 2rem auto;
    font-family: sans-serif !important;
    font-size: 1.2rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 20px;
  }

  header {
    margin-top: 10px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .logo-container {
    width: 100px;
    height: 100%;
    margin-right: 20px;
  }

  .logo-container img {
    max-width: 100%;
    height: auto;
  }

  .main {
    padding: 0 10px;
  }

  h2 {
    font-size: 17px;
    opacity: 0.8;
  }

  h3 {
    font-size: 15px;
    opacity: 0.8;
  }

  ul {
    padding: 0 0 0 20px;
  }

  p,
  .li {
    font-size: 1rem;
    opacity: 0.6;
  }

  .fourteen {
    color: red;
  }

  #page2 {
    padding-top: 20px;
  }

  .print {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
  }

  .line {
    width: 58%;
    height: 50px;
    background-color: rgb(236, 245, 250);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .line-1 {
    width: 38%;
    height: 50px;
    background-color: rgb(236, 245, 250);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    width: 58%;
    font-size: 9px;
    text-align: center;
  }

  .title-1 {
    width: 38%;
    font-size: 9px;
    text-align: center;
  }

  .sig-line {
    width: 58%;
    height: 100px;
    background-color: rgb(236, 245, 250);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sig-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sig-container img {
    max-width: 100%;
    height: auto;
    max-height: 90px;
  }

  .sig-line-1 {
    width: 38%;
    height: 50px;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sig {
    width: 58%;
    font-size: 9px;
    text-align: center;
  }

  .sig-1 {
    width: 38%;
    font-size: 9px;
    text-align: center;
  }

  .print-inputs {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
  }

  .print-text {
    width: 58%;
    height: 50px;
    background-color: rgb(236, 245, 250);
    text-align: center;
  }

  .print-date {
    width: 38%;
    height: 50px;
    background-color: rgb(236, 245, 250);
    text-align: center;
  }

  .signature-title {
    width: 100%;
    font-size: 9px;
    text-align: center;
  }

  p {
    font-size: 1.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  strong {
    font-weight: bold;
  }
`;

export const SignContract = () => {
  const { id, token } = useContext(Context);

  const [html, setHtml] = useState('loading');
  const [pdf, setPdf] = useState('');

  useEffect(() => {
    axios
      .get(`${prod}/api/contracts/check_new_contract/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (!res.data) {
          setHtml(null);
        }
        if (res.data) {
          setHtml(res.data.contract_html);
          setPdf(res.data.pdf_html);
        }
      });
  }, []);

  if (html === 'loading') {
    return (
      <div style={{ width: '80%', margin: '2rem auto', fontSize: '2rem', fontFamily: 'Spartan', textAlign: 'center' }}>
        <h3>Loading</h3>
        <PageSpinner />
      </div>
    );
  }
  if (!html) {
    return (
      <div style={{ width: '80%', margin: '2rem auto', fontSize: '2rem', fontFamily: 'Spartan', textAlign: 'center' }}>
        <h3>No Contract Needed To Sign</h3>
      </div>
    );
  }
  return (
    <StyledForm>
      <div className='main-container'>
        {ReactHtmlParser(html)}
        <SignatureForm pdf={pdf} />
      </div>
    </StyledForm>
  );
};

const SignatureForm = ({ pdf }) => {
  const { id, token } = useContext(Context);

  const signatureRef = useRef(null);
  const [inputs, setInputs] = useState({ name: '', date: new Date().toISOString().slice(0, 10) });
  const [modal, setModal] = useState(null);

  const location = useLocation();

  const handleNameChange = (e) => {
    setInputs({ ...inputs, name: e.target.value.toUpperCase() });
  };

  const submit = (e) => {
    e.preventDefault();

    if (signatureRef.current.isEmpty()) {
      alert('Please sign the contract');
      return;
    }
    setModal('loading');
    axios
      .patch(
        `${prod}/api/contracts/sign_contract/${id}`,
        {
          name: inputs.name,
          date: inputs.date,
          signature: signatureRef.current.toDataURL(),
          pdf,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then(() => {
        setModal('success');
      });
  };

  const clear = (e) => {
    e.preventDefault();
    signatureRef.current.clear();
  };

  if (modal === 'loading') {
    return (
      <div style={{ width: '80%', margin: '3rem auto' }}>
        <h3> Signing Contract</h3>
        <p>Please wait while we sign your contract.</p>
        <PageSpinner />
      </div>
    );
  }

  if (modal === 'success') {
    return (
      <div style={{ width: '80%', margin: '3rem auto' }}>
        <h3> Thank You</h3>
        <p>We have emailed you a copy of this contract for your records.</p>
        {location.search !== '?embed=inline' && (
          <a
            href='/'
            style={{
              backgroundColor: '#00a8ff',
              color: '#fff',
              padding: '1rem',
              borderRadius: '5px',
              textDecoration: 'none',
              fontSize: '1.5rem',
              display: 'inline-block',
            }}
          >
            Return Home
          </a>
        )}
      </div>
    );
  }
  return (
    <>
      <form onSubmit={submit}>
        <div className='details'>
          <div className='print'>
            <div className='title'>PRINT NAME</div>
            <div className='title-1'>DATE</div>
          </div>
          <div className='print-inputs'>
            <input className='print-text' type='text' value={inputs.name} onChange={handleNameChange} required />
            <input className='print-date' type='text' defaultValue={inputs.date} readOnly />
          </div>
          <div className='print'>
            <div className='signature-title'>SIGNATURE</div>
          </div>
          <SignaturePad
            options={{
              minWidth: 1,
              maxWidth: 2,
              penColor: 'rgb(0, 0, 0)',
              backgroundColor: 'rgb(236, 245, 250)',
            }}
            redrawOnResize
            height={200}
            ref={signatureRef}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button type='button' color='link' aria-label='clear' className='text-danger' onClick={clear}>
              Clear
            </button>
            <button color='primary' type='submit' aria-label='Submit'>
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
