import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import annotationPlugin from 'chartjs-plugin-annotation';
import toast from 'react-hot-toast';
import { PageSpinner } from '../../components/lib';
import { Context } from '../../context/auth';
import { prod } from '../../config/config';

import { adherence_pillar, homework_pillar, nutrition_pillar, steps_pillar } from '../../assets/pillars';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin);

const options = (title, target = 0) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: 'bottom',
    },
    title: {
      display: true,
      text: title,
    },
    annotation: {
      annotations: [
        {
          id: 'line-1',
          type: 'line',
          value: target,
          scaleID: 'y',
          borderWidth: 2,
          borderDash: [10, 5],
          backgroundColor: 'rgba(255, 99, 132, 0.25)',
        },
      ],
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Weeks',
      },
    },
    y: {
      title: {
        display: true,
        text: title,
      },
    },
  },
});

const StyledFourPillarsBoxNoData = styled.div`
  text-align: center;
  font-family: 'Spartan';

  margin-top: 2rem;

  h1 {
    font-size: 5rem;
  }

  p {
    font-size: 2rem;
  }
`;

const StyledFourPillarsBox = styled.div`
  max-width: 1100px;
  border-radius: 0.4rem;
  padding: 2.5rem 2.5rem;
  margin: 0 auto;

  * {
    font-family: 'Spartan';
  }

  .title {
    width: 100%;
    margin-bottom: 2rem;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      font-size: 3.5rem;
      font-variation-settings: 'wght' 600;
      font-family: 'Spartan', sans-serif;
    }
  }

  .chart-container {
    margin: 0 auto 2rem auto;
    height: 250px;
    display: flex;

    .pillar {
      padding: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      img {
        width: 100px;
      }
      p {
        font-size: 1rem;
        font-variation-settings: 'wght' 600;
        text-transform: uppercase;
        color: #fff;
      }

      h1 {
        line-height: 3rem;
        font-variation-settings: 'wght' 800;
      }

      .block {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 30px;
      }

      @media screen and (max-width: ${(props) => props.theme.mobile}) {
        display: none;
      }
    }

    .chart {
      width: 100%;
      overflow: hidden;

      h2 {
        font-size: 2rem;
        font-variation-settings: 'wght' 650;
        text-align: center;
        margin-top: 2rem;
      }
    }
  }
  .adherence {
    border: 2px solid rgba(227, 64, 127, 0.5);
    .pillar {
      background-color: rgba(227, 64, 127, 0.5);

      h1 {
        color: rgba(227, 64, 127);
      }

      .block {
        background-color: rgba(227, 64, 127);
      }
    }
  }

  .homework {
    border: 2px solid rgba(0, 152, 181, 0.5);
    .pillar {
      background-color: rgba(0, 152, 181, 0.5);

      h1 {
        color: rgba(0, 152, 181);
      }

      .block {
        background-color: rgba(0, 152, 181);
      }
    }
  }

  .nutrition {
    border: 2px solid rgba(82, 174, 50, 0.5);
    .pillar {
      background-color: rgba(82, 174, 50, 0.5);

      h1 {
        color: rgba(82, 174, 50);
      }

      .block {
        background-color: rgba(82, 174, 50);
      }
    }
  }

  .steps {
    border: 2px solid rgba(236, 102, 8, 0.5);
    .pillar {
      background-color: rgba(236, 102, 8, 0.5);

      h1 {
        color: rgba(236, 102, 8);
      }

      .block {
        background-color: rgba(236, 102, 8);
      }
    }
  }

  .averages {
    // flex box with four columns
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 1rem;
    gap: 1rem;
    align-items: stretch;

    .pillar-card {
      width: 25%;
      color: white;
      font-variation-settings: 'wght' 600;
      text-align: center;
      display: flex;
      padding: 1.5rem;
      justify-content: space-between;
      align-items: center;

      h2 {
        font-size: 1.75rem;
        margin-top: 0.5rem;
        font-variation-settings: 'wght' 500;
      }

      h1 {
        font-size: 2rem;
        margin-top: 2px;
      }
    }

    .adherence {
      background-color: rgba(227, 64, 127);
    }

    .homework {
      background-color: rgba(0, 152, 181);
    }

    .nutrition {
      background-color: rgba(82, 174, 50);
    }

    .steps {
      background-color: rgba(236, 102, 8);
    }

    @media screen and (max-width: ${(props) => props.theme.mobile}) {
      flex-direction: column;

      .pillar-card {
        width: 100%;
      }
    }
  }

  .overall {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
    padding: 15px;
    border: 3px solid #82358c;
    font-size: 4rem;
    border-radius: 5px;

    font-variation-settings: 'wght' 500;

    h2 {
      font-size: 1.75rem;
    }

    span {
      font-variation-settings: 'wght' 600;
      margin-left: 0.5ch;
      font-size: 2rem;
    }

    @media screen and (max-width: ${(props) => props.theme.mobile}) {
      flex-direction: column;
      text-align: center;
      font-size: 2.5rem;
      gap: 1rem;
    }
  }
`;

const FourPillarsCycleSelection = styled.select`
  padding: 0.5rem;
  margin-left: 1rem;
`;

export const FourPillars = () => {
  const [loading, setLoading] = useState(true);
  const [cycle, setCycle] = useState(0);
  const [pillars, setPillars] = useState([]);
  const [settings, setSettings] = useState({});
  const [pillarAverages, setPillarAverages] = useState({
    adherence: 0,
    homework: 0,
    nutrition: 0,
    steps: 0,
    total: 0,
  });

  const { id, token } = useContext(Context);

  const calculateAverages = (cycleNumber, pillarData, pillarSettings) => {
    const pillarCycle = pillarData[cycleNumber];

    const averages = {
      adherence:
        (
          pillarCycle.data.map((pillar) => pillar.adherence).reduce((a, b) => a + b, 0) / pillarCycle.data.length
        ).toFixed(1) * 1,
      homework:
        (
          pillarCycle.data.map((pillar) => pillar.homework).reduce((a, b) => a + b, 0) / pillarCycle.data.length
        ).toFixed(1) * 1,
      nutrition:
        (
          pillarCycle.data.map((pillar) => pillar.nutrition).reduce((a, b) => a + b, 0) / pillarCycle.data.length
        ).toFixed(1) * 1,
      steps: Math.round(
        pillarCycle.data.map((pillar) => pillar.steps).reduce((a, b) => a + b, 0) / pillarCycle.data.length
      ),
    };

    const targetPercentage = {
      adherence: pillarSettings.adherence ? (averages.adherence / pillarCycle.targets.adherence) * 100 : 0,
      homework: pillarSettings.homework ? (averages.homework / pillarCycle.targets.homework) * 100 : 0,
      nutrition: pillarSettings.nutrition ? (averages.nutrition / pillarCycle.targets.nutrition) * 100 : 0,
      steps: pillarSettings.steps ? (averages.steps / pillarCycle.targets.steps) * 100 : 0,
    };

    const total =
      (Object.values(targetPercentage).reduce((a, b) => a + b, 0) / Object.values(targetPercentage).length).toFixed(2) *
      1;

    return {
      ...averages,
      total,
    };
  };

  useEffect(() => {
    setLoading(true);
    axios
      .all([
        axios.get(`${prod}/api/fourpillars/athlete_pillar_display/${id}`, {
          headers: {
            Authorization: `${token}`,
          },
        }),
        axios.get(`${prod}/api/fourpillars/settings/${id}`, {
          headers: {
            Authorization: `${token}`,
          },
        }),
      ])
      .then(
        axios.spread((fourPillars, fourPillarsSettings) => {
          setSettings(fourPillarsSettings.data);

          if (fourPillars.data !== 'No athlete data available') {
            setPillars(fourPillars.data);

            setCycle(Math.max(...Object.keys(fourPillars.data)));
            setPillarAverages(
              calculateAverages(Math.max(...Object.keys(fourPillars.data)), fourPillars.data, fourPillarsSettings.data)
            );
          }
        })
      )
      .catch(() => {
        toast.error(
          'An error occured when attempting to fetch your Four Pillar data. Please contact your cardiac coach.'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const formatPillarData = (pillar, category, borderColor) => {
    const { data } = pillar;

    const formattedData = {
      labels: data.map((d) => d.week),
      datasets: [
        {
          label: 'Weeks',
          data: data.map((d) => d[category]),
          borderColor,
        },
      ],
    };

    return formattedData;
  };

  const setFourPillarsCycle = (e) => {
    setCycle(e.target.value);
    setPillarAverages(calculateAverages(e.target.value, pillars, settings));
  };

  if (loading) {
    return <PageSpinner />;
  }

  if (pillars.length === 0) {
    return (
      <StyledFourPillarsBoxNoData className='no-data'>
        <h1>No Four Pillar data available</h1>
        <p> There is no four pillar data to be displayed just yet. Check back soon!</p>
      </StyledFourPillarsBoxNoData>
    );
  }

  return (
    <StyledFourPillarsBox>
      <div className='title'>
        <h3>Your 4 Pillars</h3>
        {pillars !== 'No athlete data available' && (
          <FourPillarsCycleSelection
            name='fourPillarsCycle'
            id='fourPillarsCycle'
            onChange={setFourPillarsCycle}
            value={cycle}
          >
            {Object.keys(pillars).map((pillar, i) => (
              <option key={pillar} value={pillar}>
                Assessment cycle {pillar}
                {i === Object.keys(pillars).length - 1 ? ' (current)' : ''}
              </option>
            ))}
          </FourPillarsCycleSelection>
        )}
      </div>
      <div className='averages'>
        {pillarAverages &&
          Object.keys(pillarAverages).map((item, i) => {
            if (settings[item]) {
              return (
                <div key={Object.keys(pillarAverages)[i]} className={`pillar-card ${Object.keys(pillarAverages)[i]}`}>
                  <h2>
                    Average{' '}
                    {
                      // eslint-disable-next-line no-nested-ternary
                      item === 'adherence' ? 'resistance' : item === 'homework' ? 'CV' : item
                    }
                  </h2>
                  <h1>{JSON.stringify(pillarAverages[Object.keys(pillarAverages)[i]])}</h1>
                </div>
              );
            }
            return null;
          })}
      </div>
      <div className='overall'>
        <h2>Overall 4 pillar score</h2>

        <span>{pillarAverages.total}%</span>
      </div>
      <div className='charts'>
        {settings.adherence && pillars[cycle] && (
          <div className='chart-container adherence'>
            <div className='pillar'>
              <h1>1</h1>
              <div className='block'>
                <p>Resistance</p>
              </div>
              <img src={adherence_pillar} alt='adherence_pillar' />
            </div>
            <div className='chart'>
              {pillars === 'No athlete data available' ? (
                <h2>No athlete data available yet</h2>
              ) : (
                <Line
                  options={options('Resistance', pillars[cycle].targets ? pillars[cycle].targets.adherence : 0)}
                  data={formatPillarData(pillars[cycle], 'adherence', '#e3407f')}
                />
              )}
            </div>
          </div>
        )}
        {settings.homework && pillars[cycle] && (
          <div className='chart-container homework'>
            <div className='pillar'>
              <h1>2</h1>
              <div className='block'>
                <p>CV</p>
              </div>
              <img src={homework_pillar} alt='homework_pillar' />
            </div>
            <div className='chart'>
              {pillars === 'No athlete data available' ? (
                <h2>No athlete data available</h2>
              ) : (
                <Line
                  options={options('CV', pillars[cycle].targets ? pillars[cycle].targets.homework : 0)}
                  data={formatPillarData(pillars[cycle], 'homework', '#0098b5')}
                />
              )}
            </div>
          </div>
        )}

        {settings.nutrition && pillars[cycle] && (
          <div className='chart-container nutrition'>
            <div className='pillar'>
              <h1>3</h1>
              <div className='block'>
                <p>Nutrition</p>
              </div>
              <img src={nutrition_pillar} alt='adherence_pillar' />
            </div>
            <div className='chart'>
              {pillars === 'No athlete data available' ? (
                <h2>No athlete data available</h2>
              ) : (
                <Line
                  options={options('Nutrition', pillars[cycle].targets ? pillars[cycle].targets.nutrition : 0)}
                  data={formatPillarData(pillars[cycle], 'nutrition', '#52ae32')}
                />
              )}
            </div>
          </div>
        )}

        {settings.steps && pillars[cycle] && (
          <div className='chart-container steps'>
            <div className='pillar'>
              <h1>4</h1>
              <div className='block'>
                <p>Steps</p>
              </div>
              <img src={steps_pillar} alt='adherence_pillar' />
            </div>
            <div className='chart'>
              {pillars === 'No athlete data available' ? (
                <h2>No athlete data available</h2>
              ) : (
                <Line
                  options={options('Steps', pillars[cycle].targets ? pillars[cycle].targets.steps : 0)}
                  data={formatPillarData(pillars[cycle], 'steps', '#ec6608')}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </StyledFourPillarsBox>
  );
};
