import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useContext } from 'react';
import Navbar from './components/Navbar';
import {
  ProfilePage,
  SessionsPage,
  AssessmentsPage,
  LibraryPage,
  EducationPage,
  SettingsPage,
  MessageCoachPage,
  BookConsultationPage,
  VideoPlayerPage,
  EducationPostPage,
  SignContract,
  ChangePasswordPage,
  FourPillars,
} from './pages';

import { Context } from './context/auth';

import { lastActive } from './helpers/lastLoggedIn';

const StyledPageContainer = styled.div``;

const AuthenticatedApp = () => {
  const { getAuthenticatedUser, id, token } = useContext(Context);
  const location = useLocation();

  if (getAuthenticatedUser === false && location.pathname !== '/change-password') {
    return <Navigate to='/change-password' />;
  }

  lastActive(id, token);

  return (
    <StyledPageContainer>
      <Navbar />
      <Routes>
        <Route exact path='/' element={<ProfilePage />} />
        <Route path='/sessions' element={<SessionsPage />} />
        <Route path='/assessments' element={<AssessmentsPage />} />
        <Route path='/library' element={<LibraryPage />} />
        <Route path='/video/:video_id' element={<VideoPlayerPage />} />
        <Route path='/education' element={<EducationPage />} />
        <Route path='/education/:education_post' element={<EducationPostPage />} />
        <Route path='/settings' element={<SettingsPage />} />
        <Route path='/message/:staff_id' element={<MessageCoachPage />} />
        <Route path='/consultation' element={<BookConsultationPage />} />
        <Route path='/contract' element={<SignContract />} />
        <Route path='/change-password' element={<ChangePasswordPage />} />
        <Route path='/four-pillars' element={<FourPillars />} />
        <Route
          path='*'
          element={
            <main style={{ padding: '1rem' }}>
              <p>There is nothing here!</p>
            </main>
          }
        />
      </Routes>
    </StyledPageContainer>
  );
};

export default AuthenticatedApp;
