import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { HiOutlineMail } from 'react-icons/hi';
import { VscGraphLine } from 'react-icons/vsc';

import axios from 'axios';
import toast from 'react-hot-toast';
import { Image } from 'cloudinary-react';
import { format } from 'date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import ReactPlayer from 'react-player/lazy';
import { prod } from '../../config/config';
import { Context } from '../../context/auth';
import { FooterQuote } from '../../components/lib';

import sciencecast_banner from '../../assets/sciencecast_banner.png';

import { assessment_report, nurse_consultant } from '../../assets/profilebuttons';

import levels from '../../assets/membership';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin);

const StyledPageContainer = styled.main`
  max-width: 1100px;
  padding: 2.5rem 2.5rem;
  margin: 0 auto;

  hr {
    margin: 2rem 0;
    opacity: 0.1;
  }

  .topbar {
    display: flex;
    width: 100%;
    margin-bottom: 2rem;
    justify-content: space-between;

    @media screen and (max-width: ${(props) => props.theme.mobile}) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  .hads-exe {
    display: flex;
    flex-direction: row;
    gap: 6rem;

    @media screen and (max-width: ${(props) => props.theme.mobile}) {
      flex-direction: column;
      gap: 1rem;
    }

    margin-top: 1rem;
    justify-content: center;
  }

  .sciencecast-and-suggested {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    @media screen and (max-width: ${(props) => props.theme.mobile}) {
      flex-direction: column;
    }
  }
`;

const StyledActionBox = styled(Link)`
  width: 47.5%;
  height: 7rem;
  font-size: 1.4rem;
  background-color: ${(props) => props.theme.blue};
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.theme.black};
  font-variation-settings: 'wght' 650;
  text-transform: uppercase;
  transition: 0.2s ease-in-out;
  border: 1px solid ${(props) => props.theme.white};
  padding: 1rem;

  span {
    padding-top: 0.5rem;
  }

  :hover {
    transition: 0.2s ease-in-out;
    background-color: #3bb4dd;
  }

  @media screen and (max-width: ${(props) => props.theme.mobile}) {
    width: 100%;
  }

  img {
    width: 6em;
  }
`;

const ActionBox = ({ text, url, icon }) => (
  <StyledActionBox to={url}>
    {icon}
    <span>{text}</span>
  </StyledActionBox>
);

const StyledProfileBox = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: stretch;
  gap: 1rem;

  @media screen and (max-width: ${(props) => props.theme.mobile}) {
    flex-direction: column;
  }

  .left {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 57%;
    flex-direction: column;
    padding: 2.5rem;
    background-color: #ededed;

    @media screen and (max-width: ${(props) => props.theme.mobile}) {
      max-width: 100%;
      width: 100%;
    }

    h2 {
      width: 100%;
      text-align: center;
      font-size: 7rem;
      font-variation-settings: 'wght' 750;
      color: ${(props) => props.theme.offblue};
      text-transform: uppercase;
      font-family: 'Triplex', sans-serif;
    }

    .team {
      display: flex;
      margin-top: 2rem;
      justify-content: space-evenly;
      width: 100%;

      @media screen and (max-width: ${(props) => props.theme.mobile}) {
        // flex-direction: column;
        flex: 50%;
        flex-wrap: wrap;
      }

      .img-cont {
        padding: 0.25rem;
        width: 28%;
        text-align: center;
        font-size: 1.3rem;
        @media screen and (max-width: ${(props) => props.theme.mobile}) {
          width: 50%;
        }
      }
      img {
        max-width: 100%;
        height: auto;
        margin-bottom: 1rem;
      }

      .rounded-circle {
        border-radius: 50% !important;
      }
      .team-member-name {
        font-variation-settings: 'wght' 650;
      }
      .job-title {
        color: ${(props) => props.theme.mediumgray};
        font-variation-settings: 'wght' 550;
        padding: 0.4rem 0;
      }
    }
  }
  .right {
    width: 43%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: ${(props) => props.theme.mobile}) {
      margin-top: 2rem;
      width: 100%;
    }

    .progress-and-assessment {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 2.5rem;
      background-color: ${(props) => props.theme.offblue};
    }
    h2 {
      font-size: 6rem;
      color: ${(props) => props.theme.offblue};
      margin-bottom: 2rem;
    }
    p {
      font-size: 1.5rem;
    }

    .sub-title {
      color: white;
      font-size: 1.75rem;
      font-variation-settings: 'wght' 550;
      margin-bottom: 2rem;
    }

    .ax {
      color: #fff;
      margin-top: 2rem;
      font-variation-settings: 'wght' 700;
      font-size: 2rem;
      text-transform: uppercase;
    }

    .ax-time {
      color: #fff;
      font-variation-settings: 'wght' 700;
      font-size: 5rem;
      text-align: center;
      margin-top: 1rem;
      text-transform: uppercase;
    }

    .no-assessment {
      color: #fff;
      font-variation-settings: 'wght' 600;
      font-size: 2rem;
      margin-top: 3rem;
    }

    .progress {
      width: 95%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 100%;

      hr {
        width: 100%;
        border-bottom: 1px solid ${(props) => props.theme.white};
        opacity: 0.5;
      }

      .item {
        display: flex;
        align-items: center;

        p {
          color: white;
          margin-top: 1rem;
          font-size: 1.5rem;
          font-variation-settings: 'wght' 700;
          text-transform: uppercase;
        }

        p:first-child {
          flex: 1;
        }

        p:last-child {
          font-size: 2.5rem;
        }
      }

      @media screen and (max-width: ${(props) => props.theme.mobile}) {
        .item:last-child {
          flex-direction: column;
        }

        h2 {
          margin-top: 2rem;
          font-variation-settings: 'wght' 600;
          text-transform: uppercase;
          text-align: center;
        }

        .membership {
          display: flex;
          flex-direction: row;
          justify-content: center;

          img {
            max-width: 50px;
            max-height: 50px;
          }
        }

        .no-plan {
          font-size: 2.5rem;
          text-transform: none;
          padding: 1rem;
        }
      }
    }
  }

  .mail-hover:hover {
    color: #44c8f5;
    transition: 0.2s ease-in-out;
  }
`;

const StyledMembershipLevel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ededed;
  padding: 1rem;

  h3 {
    font-size: 2rem;
    font-variation-settings: 'wght' 600;
    text-transform: uppercase;
  }

  h4 {
    font-size: 1.5rem;
    font-variation-settings: 'wght' 750;

    span {
      color: #0098b5;
    }
  }

  .levels {
    display: flex;
    flex-direction: row;
    justify-content: center;
    img {
      max-width: 50px;
      max-height: 50px;
    }
    img:not(.active) {
      filter: grayscale(1);
    }
  }
`;

const MemberShipLevel = () => {
  const { id, token } = useContext(Context);
  const [membership, setMembership] = useState(null);

  useEffect(() => {
    axios
      .get(`${prod}/api/user/membership_level/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setMembership(res.data);
      })
      .catch(() => {
        toast.error('Error fetching membership level');
      });
  }, []);

  const membershipLevel = levels.find((level) => level.name === membership);

  if (!membershipLevel) return null;

  return (
    <StyledMembershipLevel>
      <h3>Your Membership Level</h3>
      <h4>
        <span>You are currently a</span> {membership} <span>athlete</span>
      </h4>
      <div className='levels'>
        {levels.map((img) => {
          if (img.name === 'Tracker' && membership === 'Tracker') {
            return <img key={img.name} src={img.image} alt={img.name} className='active' />;
          }
          if ((img.name === 'Athlete Academy' || img.name === 'Tracker') && membership === 'Athlete Academy') {
            return (
              <img
                key={img.name}
                src={img.image}
                alt={img.name}
                className={img.name === membership ? 'active' : null}
              />
            );
          }
          if (img.name !== 'Athlete Academy' && img.name !== 'Tracker') {
            return (
              <img
                key={img.name}
                src={img.image}
                alt={img.name}
                className={img.name === membership ? 'active' : null}
              />
            );
          }
          if (img.name === 'Upkeep' && (membership === 'Upkeep' || membership === 'Qualifier')) {
            return (
              <img
                key={img.name}
                src={img.image}
                alt={img.name}
                className={img.name === membership ? 'active' : null}
              />
            );
          }
          return null;
        })}
      </div>
    </StyledMembershipLevel>
  );
};

const ProfileBox = () => {
  const [team, setTeam] = useState({});
  const [programmePosition, setProgrammePosition] = useState(null);
  const [assessmentDate, setAssessmentDate] = useState({
    date: '',
    time: '',
  });
  const [assessmentCycle, setAssessmentCycle] = useState('');
  const { id, user, token } = useContext(Context);

  useEffect(() => {
    axios
      .get(`${prod}/api/user/team/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data) {
          setTeam(res.data);
        }
      })
      .catch(() => toast.error('An error occured retrieving your team members'));

    axios
      .get(`${prod}/api/user/programme_position/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setProgrammePosition(res.data);
      })
      .catch(() => toast.error('An error occured retrieving your programme position'));

    axios
      .get(`${prod}/api/assessment/next_assessment_date/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (new Date(res.data.date) > new Date()) {
          setAssessmentDate(res.data);
        }
      })
      .catch(() => {
        toast.error('An error occured retrieving your next assessment date');
      });

    axios
      .get(`${prod}/api/user/weeks_remaining/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setAssessmentCycle(res.data);
      })
      .catch(() => {
        toast.error('An error occured retrieving your assessment cycle');
      });
  }, []);

  return (
    <>
      <StyledProfileBox>
        <div className='left'>
          <h2>Team {user && user.split(' ').pop()}</h2>
          <div className='team'>
            {team.ces && (
              <div className='img-cont'>
                <Image
                  cloudName='dmyzytt5mrdsygn90e3ismi'
                  publicId={team.ces.image.user_image}
                  format={team.ces.image.format}
                  className='rounded-circle img-thumbnail'
                />
                <p className='team-member-name'>{team.ces.user_name}</p>
                <p className='job-title'>Cardiac Coach</p>
                <a href={`/message/${team.ces._id}`}>
                  <HiOutlineMail className='mail-hover' size='2em' />
                </a>
              </div>
            )}
            {team.experience_manager && (
              <div className='img-cont'>
                <Image
                  cloudName='dmyzytt5mrdsygn90e3ismi'
                  publicId={team.experience_manager.image.user_image}
                  format={team.experience_manager.image.format}
                  className='rounded-circle img-thumbnail'
                />
                <p className='team-member-name'>{team.experience_manager.user_name}</p>
                <p className='job-title'>Experience Manager</p>
                <a href={`/message/${team.experience_manager._id}`}>
                  <HiOutlineMail className='mail-hover' size='2em' />
                </a>
              </div>
            )}
            <div className='img-cont'>
              <img
                src='https://res.cloudinary.com/dmyzytt5mrdsygn90e3ismi/image/upload/v1/headshots/Helen_Goodall_500_wtibkl.png'
                alt='headshot'
              />
              <p className='team-member-name'>Helen Goodall</p>
              <p className='job-title'>Clinical Nurse</p>
              <a href='/message/5e83564480a2920017876644'>
                <HiOutlineMail className='mail-hover' size='2em' />
              </a>
            </div>
          </div>
        </div>
        <div className='right'>
          <MemberShipLevel />
          <div className='progress-and-assessment'>
            <p className='sub-title'>In this assessment cycle, you have:</p>
            <div className='progress'>
              <div className='item'>
                <p>Weeks remaining</p>
                <p>{assessmentCycle || '-'}</p>
              </div>
              <hr />
              <div className='item'>
                <p>Sessions remaining</p>
                <p>{programmePosition || '-'}</p>
              </div>
              <hr />
              <div className='item'>
                <p>Next Assessment</p>
                <p>
                  {assessmentDate && assessmentDate.date && format(new Date(assessmentDate.date), 'dd MMM')} -{' '}
                  {assessmentDate && assessmentDate.time}{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </StyledProfileBox>
    </>
  );
};
const StyledSciencecastBox = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${(props) => props.theme.mobile}) {
    width: 100%;
  }

  img {
    width: 100%;
    max-height: 320px;
    object-fit: cover;
  }

  .science-content {
    padding: 3rem 5rem;
    text-align: center;
    background-color: ${(props) => props.theme.yellow};

    .title {
      width: 100%;
      margin-bottom: 2rem;
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;

      h3 {
        font-size: 2.5rem;
        font-variation-settings: 'wght' 750;
        text-transform: uppercase;
      }
    }

    p {
      font-size: 1.35rem;
      font-variation-settings: 'wght' 600;
      line-height: 2.25rem;
    }

    .link {
      margin-top: 2rem;
      a {
        background-color: ${(props) => props.theme.black};
        color: #fff;
        font-size: 1.75rem;
        font-variation-settings: 'wght' 550;
        padding: 0.8rem 0.75rem;
        transition: color 0.2s ease-in-out;
        text-transform: uppercase;
      }

      a:hover {
        color: #dbdada;
      }
    }
  }
`;

const ScienceCastBox = () => (
  <StyledSciencecastBox>
    <div className='science-content'>
      <div className='title'>
        <h3>Sciencecast</h3>
      </div>
      <p>
        In our Sciencecast you will ﬁnd tips, health advice and fascinating research summaries to help you better
        understand and manage your health.
      </p>
      <div className='link'>
        <a href='https://www.cpandr.co.uk/sciencecast/' target='_blank' rel='noreferrer noopener'>
          Read Latest
        </a>
      </div>
    </div>
    <img src={sciencecast_banner} alt='sciencecast' />
  </StyledSciencecastBox>
);

const StyledContentBox = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  padding: 1rem;
  background-color: #e6f5fe;

  @media screen and (max-width: ${(props) => props.theme.mobile}) {
    width: 100%;
  }

  .title {
    font-size: 2.5rem;
    font-variation-settings: 'wght' 750;
    text-align: center;
    margin-top: 1.5rem;
    text-transform: uppercase;
  }

  .suggested-content {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
  }
`;

const SuggestContent = () => (
  <StyledContentBox>
    <div className='title'>
      <h3>Featured Content</h3>
    </div>
    <div className='suggested-content'>
      <div>
        <ReactPlayer url='https://vimeo.com/735239514/8b62c0d04a' controls width='100%' />
      </div>
    </div>
  </StyledContentBox>
);

const StyledJoinSession = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 2rem;
  padding: 1rem 1rem 1rem 0;

  .session-container {
    width: 100%;

    .session {
      margin: 2rem 4rem 2rem 0;
      padding: 2rem;
      background-color: #e6f5fe;
      position: relative;
      display: flex;

      .session-info {
        padding: 2rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #e6f5fe;
        width: 87%;

        @media screen and (max-width: ${(props) => props.theme.mobile}) {
          width: 100%;
        }

        h3 {
          font-size: 2rem;
          font-variation-settings: 'wght' 600;
          text-transform: uppercase;
          color: #000;

          @media screen and (max-width: ${(props) => props.theme.mobile}) {
            text-align: center;
          }
        }

        p {
          margin-top: 1rem;
          font-size: 1.3rem;
          font-variation-settings: 'wght' 550;
          color: #000;
          width: max-content;

          @media screen and (max-width: ${(props) => props.theme.mobile}) {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }

    .links {
      display: flex;
      margin: auto;
      gap: 1rem;
      a {
        padding: 1rem;
        color: black;
        background-color: ${(props) => props.theme.yellow};
        font-size: 1.75rem;
        font-variation-settings: 'wght' 550;
        transition: 0.2s ease-in-out;
        text-align: center;
      }

      a:hover,
      a:focus {
        background-color: ${(props) => props.theme.black};
        color: white;
      }
    }

    .session-member {
      position: absolute;
      right: 0;
      top: 1rem;
      top: 50%;
      transform: translateY(-50%);
      img {
        width: 175px;
        height: 175px;
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.mobile}) {
    padding: 0;

    .session-container {
      display: flex;
      background-color: #e6f5fe;
      flex-direction: column;
      align-items: center;
      padding: 1rem;
      .session {
        margin: 0;
        padding-bottom: 0;
        .session-info {
          flex-direction: column;

          .links {
            flex-direction: column;

            a {
              margin-left: 0;
              margin-top: 1rem;
            }
          }
        }
      }

      .session-member {
        position: unset;
        transform: unset;
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.tablet}) {
    .session-container {
      .session {
        .session-info {
          .links {
            flex-direction: column;
          }
        }
      }
    }
  }
`;

const JoinSession = () => {
  const [session, setSession] = useState(null);
  const { id, token } = useContext(Context);

  useEffect(() => {
    axios
      .get(`${prod}/api/user/upcoming_session/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data) {
          setSession(res.data);
        }
      })
      .catch(() => {
        toast.error('An error occured retrieving your upcoming session');
      });
  }, []);

  if (session) {
    return (
      <StyledJoinSession>
        <div className='session-container'>
          <div className='session'>
            <div className='session-info'>
              <div>
                <h3>
                  {session.field_data.booking_length} Minute {session.field_data.session_type}
                </h3>
                <p>
                  {session.field_data.user.field_data.user_name}
                  <br />
                  <br />
                  {format(new Date(session.field_data.date), 'E dd/MM/yyyy')} - {session.field_data.time}{' '}
                </p>
              </div>
              {session.field_data.clinic === 'Virtual' &&
                session.field_data.session_type === 'EX: Standard session' && (
                  <div className='links'>
                    {session.field_data.user.field_data.zoom_links ? (
                      <>
                        <a
                          href={session.field_data.user.field_data.zoom_links.personal_link || ''}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <span>Join Session</span>
                        </a>
                        <a
                          href={session.field_data.user.field_data.zoom_links.link_to_hr || ''}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <span>Share Heart Rate</span>
                        </a>
                      </>
                    ) : (
                      <p>Your Cardiac Coach has not provided links.</p>
                    )}
                  </div>
                )}
            </div>{' '}
          </div>
          <div className='session-member'>
            <Image
              cloudName='dmyzytt5mrdsygn90e3ismi'
              publicId={session.field_data.user.field_data.image.user_image}
              format={session.field_data.user.field_data.image.format}
              className='rounded-circle img-thumbnail'
            />
          </div>
        </div>
      </StyledJoinSession>
    );
  }
  return null;
};

export const ProfilePage = () => (
  <>
    <StyledPageContainer>
      <div className='topbar'>
        <ActionBox
          url='/four-pillars'
          text='Your 4 Pillars Data'
          icon={
            <VscGraphLine
              style={{
                color: 'black',
                marginRight: '1rem',
                marginLeft: '1rem',
              }}
              size='2.75em'
            />
          }
        />
        <ActionBox
          url='/assessments'
          text='Latest Assessment Report'
          icon={<img src={assessment_report} alt='assessment report' />}
        />
      </div>
      <JoinSession />
      <ProfileBox />
      <div className='sciencecast-and-suggested'>
        <ScienceCastBox />
        <SuggestContent />
      </div>
      <hr />
    </StyledPageContainer>
    <FooterQuote message='A goal without a plan is just a wish' />
  </>
);
