import { Suspense, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Context } from './context/auth';

import { FullPageSpinner } from './components/lib';

import AuthenticatedApp from './AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp';
import './styles/global.css';

function App() {
  const { user, id, token, getAuthenticatedUser } = useContext(Context);

  return (
    <Routes>
      <Route
        path='*'
        element={
          <Suspense fallback={<FullPageSpinner />}>
            {getAuthenticatedUser !== null &&
            getAuthenticatedUser !== 'loading' &&
            user &&
            id !== 'loading' &&
            user &&
            id !== null &&
            token !== 'loading' &&
            token !== null ? (
              <AuthenticatedApp />
            ) : (
              <UnauthenticatedApp />
            )}
          </Suspense>
        }
      />
    </Routes>
  );
}

export default App;
