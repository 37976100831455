export default {
  success: {
    style: {
      background: 'green',
      color: 'white',
      fontSize: '1rem',
      fontFamily: '"Spartan", sans-serif',
    },
  },
  error: {
    icon: false,
    style: {
      background: '#F47174',
      color: '#fff',
      width: '300px',
      fontSize: '1.3rem',
      padding: '1rem 0.5rem',
      fontFamily: '"Spartan", sans-serif',
    },
  },
};
