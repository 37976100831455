import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Context } from '../../context/auth';
import { PageSpinner, FooterQuote } from '../../components/lib';
import { prod } from '../../config/config';

const StyledPageContainer = styled.main`
  max-width: 1100px;
  padding: 3.5rem 2.5rem;
  /* border: 1px solid red; */
  margin: 0 auto;

  hr {
    border: 0;
    height: 2px;
    background-color: black;
  }

  .recommended-videos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    h3 {
      font-size: 2rem;
      font-variation-settings: 'wght' 550;
      margin-bottom: 1rem;
    }

    div {
      display: flex;
      flex-wrap: wrap;

      a {
        margin: 0.75rem;
      }
    }

    @media screen and (max-width: ${(props) => props.theme.mobile}) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const StyledVideoBox = styled(Link)`
  flex: 1 0 25rem;
  margin: 1.5rem;
  font-size: 1.4rem;
  background-color: ${(props) => props.theme.lightgray};
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  position: relative;
  color: ${(props) => props.theme.black};

  img {
    width: 100%;
    flex-shrink: 0;
    vertical-align: middle;
  }

  .title {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.5rem;

    p {
      font-size: 1.5rem;
      font-variation-settings: 'wght' 550;
      padding: 1rem 0;
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.yellow};
  }

  @media screen and (max-width: ${(props) => props.theme.mobile}) {
    width: 100%;
    margin: 0.5rem;
  }
`;

const StyledCategory = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 2rem 0;

  h3 {
    font-size: 2rem;
    font-variation-settings: 'wght' 550;
    margin-bottom: 1rem;
  }

  .subcategory {
    h4 {
      font-size: 1.5rem;
      font-variation-settings: 'wght' 550;
      margin-bottom: 0.5rem;
    }

    width: 100%;

    .videos {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      @media screen and (max-width: ${(props) => props.theme.mobile}) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    margin: 1rem 0;
    margin-left: 1rem;
  }
`;

const VideoBox = ({ url = '', thumbnail, title }) => (
  <StyledVideoBox to={url} thumbnail={thumbnail}>
    <div>
      <img src={thumbnail} alt={title} />
      <div className='title'>
        <p>{title}</p>
      </div>
    </div>
  </StyledVideoBox>
);

export const LibraryPage = () => {
  const { id, token } = useContext(Context);
  const [categorisedVideos, setCategorisedVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${prod}/api/user/videos/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          const filteredCategorised = res.data.reduce(
            (acc, video) => {
              if (video.recommended) {
                acc.Recommended.push(video);
                return acc;
              }
              if (!acc[video.category]) {
                acc[video.category] = [];
              }
              if (!acc[video.category][video.subcategory]) {
                acc[video.category][video.subcategory] = [];
              }
              acc[video.category][video.subcategory].push(video);
              return acc;
            },
            {
              Recommended: [],
            }
          );

          setCategorisedVideos(filteredCategorised);
        }
      })
      .catch(() => {
        toast.error('An error occured when attempting to fetch your videos. Please contact your cardiac coach.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <>
        <PageSpinner />;
        <FooterQuote message='Excellence is modest improvements consistently done' />
      </>
    );
  }

  return (
    <>
      <StyledPageContainer>
        {Object.keys(categorisedVideos).map((category) => {
          if (category === 'Recommended') {
            if (categorisedVideos[category].length === 0) {
              return null;
            }

            return (
              <StyledCategory key={category}>
                <h3>{category} By Your Coach</h3>
                <div className='recommended-videos'>
                  {categorisedVideos[category].map((video) => (
                    <VideoBox
                      key={video._id}
                      url={`/video/${video._id}`}
                      thumbnail={video.thumbnail}
                      title={video.title}
                    />
                  ))}
                </div>
                <hr />
              </StyledCategory>
            );
          }

          return (
            <StyledCategory key={category}>
              <h3>{category}</h3>
              {Object.keys(categorisedVideos[category]).map((subcategory) => (
                <div className='subcategory' key={subcategory}>
                  <h4>{subcategory}</h4>
                  <div className='videos'>
                    {categorisedVideos[category][subcategory].map((video) => (
                      <VideoBox
                        key={video._id}
                        url={`/video/${video._id}`}
                        title={video.title}
                        thumbnail={video.thumbnail}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </StyledCategory>
          );
        })}
      </StyledPageContainer>

      <FooterQuote message='Excellence is modest improvements consistently done' />
    </>
  );
};
