import styled from 'styled-components';
import axios from 'axios';
import { useState, useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Image } from 'cloudinary-react';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';
import { Context } from '../../context/auth';
import { PageSpinner, FooterQuote } from '../../components/lib';
import { prod } from '../../config/config';

const StyledDownloadButton = styled.div`
  display: flex;
  justify-content: flex-end;

  a {
    background-color: #fff;
    width: fit-content;
    padding: 1.25rem;
    color: black;
    font-size: 1.5rem;
    font-variation-settings: 'wght' 550;
    transition: 0.2s ease-in-out;
    margin-bottom: 2rem;
    background-color: ${(props) => props.theme.yellow};
  }

  a:hover,
  a:focus {
    background-color: ${(props) => props.theme.black};
    color: white;
    transition: 0.2s ease-in-out;
  }
`;

const StyledSessionBox = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 2rem;
  padding: 1rem 1rem 1rem 0;

  .session-container {
    width: 100%;

    .session {
      margin: 2rem 4rem 2rem 0;
      padding: 2rem;
      background-color: #e6f5fe;
      position: relative;
      display: flex;

      .session-info {
        padding: 2rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #e6f5fe;
        width: 100%;

        h3 {
          font-size: 2rem;
          font-variation-settings: 'wght' 600;
          text-transform: uppercase;
          color: #000;

          span {
            font-size: 1.5rem;
          }
        }

        p {
          margin-top: 1rem;
          font-size: 1.3rem;
          font-variation-settings: 'wght' 550;
          color: #000;
          width: max-content;
        }
      }
    }

    // background colour pattern. nth term
    // 1 background-color: #ededed
    // 2 background-color: #e6f5fe
    // 3 background-color: #fff4b5
    // 4 background-color: #f9dfe7
    // 5 background-color: #fde4d0
    // 6 background-color: #e4efda

    .links {
      display: flex;
      a {
        margin-left: 2rem;
        padding: 1rem;
        color: black;
        border: 3px solid ${(props) => props.theme.yellow};
        font-size: 1.5rem;
        font-variation-settings: 'wght' 550;
        transition: 0.2s ease-in-out;
      }

      a:hover,
      a:focus {
        background-color: ${(props) => props.theme.yellow};
        transition: 0.2s ease-in-out;
      }
    }

    .session-member {
      position: absolute;
      right: 0;
      top: 1rem;
      top: 50%;
      transform: translateY(-50%);
      img {
        width: 175px;
        height: 175px;
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.mobile}) {
    padding: 0;

    .session-container {
      display: flex;
      background-color: #e6f5fe;
      flex-direction: column;
      align-items: center;
      padding: 1rem;
      .session {
        margin: 0;
        padding-bottom: 0;
        .session-info {
          flex-direction: column;

          .links {
            flex-direction: column;

            a {
              margin-left: 0;
              margin-top: 1rem;
            }
          }
        }
      }

      .session-member {
        position: unset;
        transform: unset;
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.tablet}) {
    .session-container {
      .session {
        .session-info {
          .links {
            flex-direction: column;
          }
        }
      }
    }
  }
`;

const StyledPageContainer = styled.main`
  max-width: 900px;
  padding: 2.5rem 2.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  ${StyledSessionBox}:nth-child(6n + 1) {
    .session-container {
      @media screen and (max-width: ${(props) => props.theme.mobile}) {
        background-color: #ededed;
      }
      .session {
        background-color: #ededed;

        .session-info {
          background-color: #ededed;
        }
      }
    }
  }

  ${StyledSessionBox}:nth-child(6n + 2) {
    .session-container {
      @media screen and (max-width: ${(props) => props.theme.mobile}) {
        background-color: #e6f5fe;
      }
      .session {
        background-color: #e6f5fe;

        .session-info {
          background-color: #e6f5fe;
        }
      }
    }
  }

  ${StyledSessionBox}:nth-child(6n + 3) {
    .session-container {
      @media screen and (max-width: ${(props) => props.theme.mobile}) {
        background-color: #fff4b5;
      }
      .session {
        background-color: #fff4b5;

        .session-info {
          background-color: #fff4b5;
        }
      }
    }
  }

  ${StyledSessionBox}:nth-child(6n + 4) {
    .session-container {
      @media screen and (max-width: ${(props) => props.theme.mobile}) {
        background-color: #f9dfe7;
      }
      .session {
        background-color: #f9dfe7;

        .session-info {
          background-color: #f9dfe7;
        }
      }
    }
  }

  ${StyledSessionBox}:nth-child(6n + 5) {
    .session-container {
      @media screen and (max-width: ${(props) => props.theme.mobile}) {
        background-color: #fde4d0;
      }
      .session {
        background-color: #fde4d0;

        .session-info {
          background-color: #fde4d0;
        }
      }
    }
  }

  ${StyledSessionBox}:nth-child(6n + 6) {
    .session-container {
      @media screen and (max-width: ${(props) => props.theme.mobile}) {
        background-color: #e4efda;
      }
      .session {
        background-color: #e4efda;

        .session-info {
          background-color: #e4efda;
        }
      }
    }
  }
`;

const SessionBox = ({ image, cc, date, location, length, type, unused }) => {
  if (unused) {
    return (
      <StyledSessionBox>
        <div className='session-container'>
          <div className='session'>
            <div className='session-info'>
              <div>
                <h3>Unused</h3>
                <br />

                <p>
                  {length} Minute {type} - <span>{location}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </StyledSessionBox>
    );
  }

  return (
    <StyledSessionBox>
      <div className='session-container'>
        <div className='session'>
          <div className='session-info'>
            <div>
              <h3>
                {length} Minute {type} - <span>{location}</span>
              </h3>
              <p>
                {cc}
                <br />
                <br />
                {format(new Date(date.date), 'E dd/MM/yyyy')} - {date.time}{' '}
              </p>
            </div>
          </div>
        </div>
        <div className='session-member'>
          <Image
            cloudName='dmyzytt5mrdsygn90e3ismi'
            publicId={image.user_image}
            format={image.format}
            className='rounded-circle img-thumbnail'
          />
        </div>
      </div>
    </StyledSessionBox>
  );
};

export const SessionsPage = () => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [csvData, setCSVData] = useState([]);
  const { id, user, token } = useContext(Context);

  useEffect(() => {
    axios
      .get(`${prod}/api/user/sessions/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setSessions(res.data);
        const convertToCSV = res.data.map((session) => ({
          'Cardiac Coach': session.user.user_name,
          Date: format(new Date(session.field_data.date), 'E dd/MM/yyyy'),
          Time: session.field_data.time,
          Location: session.field_data.clinic,
          Length: session.field_data.booking_length,
          Type: session.field_data.session_type,
        }));

        setCSVData(convertToCSV);
      })
      .catch(() => {
        toast.error('Error loading sessions');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <>
        <PageSpinner />
        <FooterQuote message='The only time success comes before work is in the dictionary' />
      </>
    );
  }

  if (sessions.length === 0) {
    return (
      <>
        <StyledPageContainer>
          <h1>No sessions found</h1>
        </StyledPageContainer>
        <FooterQuote message='The only time success comes before work is in the dictionary' />
      </>
    );
  }

  return (
    <>
      <StyledPageContainer>
        <StyledDownloadButton>
          <CSVLink data={csvData} target='_blank' filename={`${user}'s Sessions`}>
            Download
          </CSVLink>
        </StyledDownloadButton>
        {sessions.map((session) => (
          <SessionBox
            key={session._id}
            image={session.user.image}
            cc={session.user.user_name}
            date={{ date: session.field_data.date, time: session.field_data.time }}
            location={session.field_data.clinic}
            length={session.field_data.booking_length}
            type={session.field_data.session_type}
            unused={session.unused}
          />
        ))}
      </StyledPageContainer>
      <FooterQuote message='The only time success comes before work is in the dictionary' />
    </>
  );
};
