import styled from 'styled-components';

const StyledPageContainer = styled.main`
  max-width: 900px;
  padding: 4.5rem 2.5rem;
  margin: 0 auto;
`;

const StyledMessageBox = styled.div`
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  padding: 2.5rem 2.5rem;
  margin: 0 auto;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.white};

  .message-cc {
    width: 150px;
    img {
      max-width: 100%;
      height: auto;
    }
  }

  h2 {
    font-size: 2rem;
    margin: 2rem 0 3rem 0;
  }
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
  }

  input {
    width: 70%;
    padding: 8px 0;
    font-size: 15px;
    line-height: 30px;
    color: ${(props) => props.theme.black};
    border: 1px solid ${(props) => props.theme.lightgray};
    border-radius: 6px;
    background: none;
    transition: all 300ms ease 0s;
    margin: 2rem 0;
  }

  button {
    width: 200px;
    padding: 8px 12px;
    font-size: 15px;
    line-height: 24px;
    border-radius: 6px;
    background: none;
    transition: all 300ms ease 0s;
    margin-top: 2.5rem;
    background-color: ${(props) => props.theme.pink};
    color: ${(props) => props.theme.white};
    border: none;
  }
`;

export const SettingsPage = () => (
  <StyledPageContainer>
    <StyledMessageBox>
      <h2>Profile Info</h2>
      <form>
        <input type='text' />
        <input type='text' />
        <input type='text' />
        <input type='text' />
        <input type='text' />
        <input type='text' />
        <button type='submit'>Update</button>
      </form>
    </StyledMessageBox>
  </StyledPageContainer>
);
