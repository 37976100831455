import { useContext, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import toast from 'react-hot-toast';

import { Context } from '../../context/auth';
import MainLogo from '../../assets/main-logo.png';
import { Spinner, Input, Button, Footer } from '../../components/lib';

import { prod } from '../../config/config';

const StyledLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  height: auto;
  background-color: white;
`;

const StyledLoginForm = styled.div`
  margin: 0 auto;
  max-width: 350px;
  width: 100%;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;

  @media screen and (max-width: 420px) {
    padding: 0 4rem;
  }

  .logo-container {
    max-width: 150px;
    margin-top: 40px;
    margin-bottom: 50px;
  }

  .logo-container img {
    max-width: 100%;
    height: auto;
  }
  form {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .forgot-password {
    all: unset;
    color: ${(props) => props.theme.blue};
    font-variation-settings: 'wght' 650;
    font-size: 1.25rem;
    text-align: right;
    width: 100%;
    font-family: 'Spartan', sans-serif;
    cursor: pointer;
  }
  .forgot-password:hover {
    text-decoration: underline;
  }

  .back-button-container {
    width: 100%;
  }

  .back-button {
    all: unset;
    color: ${(props) => props.theme.blue};
    font-variation-settings: 'wght' 650;
    font-size: 1.5rem;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Spartan', sans-serif;
  }

  .back-button:hover {
    text-decoration: underline;
  }

  h4 {
    font-variation-settings: 'wght' 650;
    font-size: 1.25rem;
    text-align: left;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: 'Spartan', sans-serif;
  }
`;

const LoginForm = () => {
  const [formState, setFormState] = useState(null);
  const [forgotPassword, setForgotPassword] = useState(false);
  const { login } = useContext(Context);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormState('loading');
    const { email, password } = e.target.elements;
    axios
      .post(`${prod}/api/auth`, { email: email.value, password: password.value })
      .then((res) => {
        setFormState(null);
        login(
          res.data.authenticated.user_name,
          res.data.authenticated._id,
          res.data.authenticated.token,
          res.data.authenticated.getAuthenticatedUser
        );
      })
      .catch(() => {
        toast.error('Invalid email, or password.');
        password.value = null;
        setFormState(null);
      });
  };

  const forgotPasswordHandler = () => {
    setForgotPassword(!forgotPassword);
    setFormState(null);
  };

  const forgotPasswordForm = (e) => {
    e.preventDefault();
    const { email_reset } = e.target.elements;
    if (email_reset.value) {
      axios
        .post(`${prod}/api/auth/forgot_password`, {
          email: email_reset.value,
        })
        .then((res) => {
          toast.success(res.data);
          setForgotPassword(false);
          setFormState(null);
        })
        .catch(() => {
          toast.error('An error occured when attempting to reset your password. Please contact your cardiac coach.');
          setForgotPassword(false);
          setFormState(null);
        });
    }
  };

  if (forgotPassword) {
    return (
      <StyledLoginForm>
        <div className='back-button-container'>
          <button type='button' className='back-button' onClick={forgotPasswordHandler}>
            Back
          </button>
        </div>
        <form
          style={{
            marginTop: '1rem',
          }}
          onSubmit={forgotPasswordForm}
        >
          <h4>Please type your email here:</h4>
          <div
            style={{
              width: '100%',
            }}
          >
            <Input type='email' name='email_reset' placeholder='Email' required autocomplete='off' />
          </div>
          <Button
            type='submit'
            style={{
              marginTop: '1rem',
            }}
          >
            Reset Password
          </Button>
        </form>
      </StyledLoginForm>
    );
  }
  return (
    <StyledLoginForm>
      <div className='logo-container'>
        <img src={MainLogo} alt='CP+R main logo' />
      </div>
      <form onSubmit={handleSubmit}>
        <Input type='email' placeholder='Email' id='email' required />
        <Input type='password' placeholder='Password' id='password' required />
        <button type='button' className='forgot-password' onClick={forgotPasswordHandler}>
          Forgot Password?
        </button>
        <Button type='submit'>{formState === 'loading' ? <Spinner /> : 'Log In'}</Button>
      </form>
    </StyledLoginForm>
  );
};

export const Login = () => (
  <StyledLoginContainer>
    <LoginForm />
    <Footer />
  </StyledLoginContainer>
);
