import { useState, useEffect, createContext } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { FullPageSpinner } from '../components/lib';

export const Context = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState('loading');
  const [id, setId] = useState('loading');
  const [token, setToken] = useState('loading');
  const [getAuthenticatedUser, setGetAuthenticatedUser] = useState('loading');

  const getCookie = () => {
    const userCookie = Cookies.get('user');
    const idCookie = Cookies.get('_id');
    const tokenCookie = Cookies.get('token');
    const authenticatedUser = Cookies.get('getAuthenticatedUserInfo');
    if (!userCookie || !idCookie || !tokenCookie || !authenticatedUser) {
      // if no cookies are set, redirect to login
      setUser(null);
      setId(null);
      setToken(null);
      setGetAuthenticatedUser(null);
    } else {
      setUser(userCookie);
      setId(idCookie);
      setToken(tokenCookie);
      setGetAuthenticatedUser(authenticatedUser === 'true');
    }
  };

  const login = async (user_name, _id, jwtToken, hasResetPassword) => {
    Cookies.set('token', jwtToken);
    Cookies.set('user', user_name);
    Cookies.set('_id', _id);
    Cookies.set('getAuthenticatedUserInfo', hasResetPassword);
    getCookie();
  };

  const logout = () => {
    Cookies.remove('user');
    Cookies.remove('token');
    Cookies.remove('_id');
    Cookies.remove('getAuthenticatedUserInfo');
    getCookie();
    setUser(null);
    setId(null);
    navigate('/');
  };

  useEffect(() => {
    getCookie();
  }, []);
  if (user === 'loading') return <FullPageSpinner />;
  return (
    <Context.Provider value={{ user, id, login, logout, token, getAuthenticatedUser }}>{children}</Context.Provider>
  );
};
