import { InlineWidget } from 'react-calendly';
import { useLocation } from 'react-router-dom';

export const BookConsultationPage = () => {
  const { pathname } = useLocation();
  return (
    <InlineWidget
      url={
        pathname === '/physio' ? process.env.REACT_APP_CALENDLY_PHYSIO_URL : process.env.REACT_APP_CALENDLY_CLINICAL_URL
      }
    />
  );
};
