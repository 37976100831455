export default {
  // colors
  black: '#231f20',
  mediumgray: '#7a7a7a',
  lightgray: '#e3e3e3',
  white: '#fafafa',
  offwhite: '#F5F7F9',
  yellow: '#ffe512',
  blue: '#44c8f5',
  offblue: '#0098b5',
  lightblue: '#e6f5fe',
  pink: '#e3407f',
  green: '#4db848',
  orange: '#f37021',
  // fonts
  main: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  // breakpoints
  mobile: '819px',
  tablet: '1024px',
};
