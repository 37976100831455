import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Toaster } from 'react-hot-toast';
import Theme from '../styles/theme';
import GlobalStyle from '../styles/global';
import { AuthProvider } from './auth';
import ToastOptions from '../styles/toast';

function AppProviders({ children }) {
  return (
    <Router>
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <Toaster toastOptions={ToastOptions} />
        <AuthProvider>{children}</AuthProvider>
      </ThemeProvider>
    </Router>
  );
}

export default AppProviders;
