import { useEffect } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import styled from 'styled-components';
import DownloadIOS from '../../assets/Download_on_the_App_Store_Badge.svg';
import DownloadAndroid from '../../assets/google-play-badge.png';

export const AppDownload = () => {
  useEffect(() => {
    if (isAndroid) {
      window.location =
        'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=uk.co.cpandr.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
    }

    if (isIOS) {
      window.location.href = 'https://apps.apple.com/app/cp-r-the-clubhouse/id6443661359';
    }
  }, []);

  return (
    <Container>
      <Title>You should be redirected shortly.</Title>
      <Paragraph>
        In case you have not been redirected. Click on either the App Store or Play Store button below to download CP+R
        The Clubhouse
      </Paragraph>
      <ButtonContainer>
        <ButtonLink href='https://apps.apple.com/app/cp-r-the-clubhouse/id6443661359' target='_blank'>
          <Image src={DownloadIOS} alt='Download for IOS' />
        </ButtonLink>
        <ButtonLink
          href='https://play.google.com/store/apps/details?id=uk.co.cpandr.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
          target='_blank'
        >
          <Image src={DownloadAndroid} alt='Download for Android' />
        </ButtonLink>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  flex-direction: column;
  gap: 3rem;
`;

const Title = styled.h1`
  font-family: 'Spartan', sans-serif;
  text-align: center;
  font-size: 4rem;
`;

const Paragraph = styled.p`
  font-size: 2rem;
  font-family: 'Spartan', sans-serif;
  max-width: 750px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 2rem;
`;

const ButtonLink = styled.a``;

const Image = styled.img`
  max-height: 40px;
`;
